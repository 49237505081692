<template>
    <div class="getDoorPassword">
        <div class="getDoorPasswordDiv">
            <van-overlay :show="isMobileShow">
                <div class="wrapper" @click.stop>
                    <div class="block">
<!--                        获取密码的div-->
                        <div class="getPassword">
                            获取密码
                        </div>
<!--                        公门密码展示的DIV-->
                        <div class="publicDoorPassWord" v-if="doorPassWord != undefined && doorPassWord != ''">
                            <p>公门密码</p>
                            <p class="password">{{doorPassWord}} </p>
                        </div>
<!--                        分割线-->
                        <div class="rule" v-if="publicDoorPassWord != undefined && publicDoorPassWord != '' && doorPassWord != undefined && doorPassWord != ''"></div>
<!--                        房门密码展示的DIV-->
                        <div class="privateDoorPassWord" v-if="publicDoorPassWord != undefined && publicDoorPassWord != ''">
                            <p>房门密码</p>
                            <p class="password">{{publicDoorPassWord}} </p>
                        </div>
                    </div>
                    <div class="closeImg" @click="closeMobileModel">
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    import { Overlay,Button } from 'vant';
    import {queryMyPageInit} from "../../../getData/getData";
    import {responseUtil} from "../../../libs/rongxunUtil";
    export default {
        components: {
            [Overlay  .name]:Overlay,
            [Button  .name]:Button
        },
        props: {
            doorPassWord: {
                type: String,
                default:''
            },
            isMobileShow: {
                type: Boolean,
                default:false
            },
            publicDoorPassWord: {
                type: String,
                default:''
            },
        },

        data() {
            return {
                show: false,
                publicDoorPassword:'',
                privateDoorPassword:''

            }
        },
        mounted: function() {
            // this.initData();
        },
        methods:{
            // initData(){
            //     var that = this
            //     let initData = {}
            //     initData.staff_id='16'
            //     initData.currentPage='1'
            //     initData.numberPage='1'
            //     userGetPassword(initData).then(function (response) {
            //         responseUtil.dealResponse(that, response, () => {
            //             that.publicDoorPassword = response.data.data
            //
            //         })
            //     })
            // },

            //关闭密码弹窗
            closeMobileModel(){
                this.$emit('hideDialog');
            }
        }
    }
</script>

<style lang="less" scoped >
    @import "../../../assets/less/maskLayer.less";
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
/*    获取密码DIV*/
    .getPassword{
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
/*    公门密码 房门密码*/
    .publicDoorPassWord,.privateDoorPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }
    /*分割线*/
    .rule{
        width: 70%;
        margin: 20px auto;
        text-align: center;
        border: 0.5px solid #eeeeee;
    }
    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 70px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
</style>