<template>

    <div>
<!--        <van-nav-bar title="确认带看" class="nav_bar_title"  @click-left="leftReturn"  fixed left-arrow/>-->
        <rx-navBar title="确认带看" ></rx-navBar>
<!--        获取密码-->
        <div class="getCode" @click="getDoorPassword()" v-if="isHaveDevice=='0'">
            <img class="getCodeIcon" src="../../assets/images/RepairPending-getPwd.png">
            <div class="sp">获取密码</div>
            <get-door-password :doorPassWord="temporaryDoorPassWord" :publicDoorPassWord="temporaryPublicDoorPwd" :isMobileShow="isMobileShow"  @hideDialog="hideDialog"></get-door-password>
        </div>
<!--        带看结果-->
        <!--楼盘地图位置-->
        <div id="container"></div>

        <div class="takeALookResult">带看结果</div>
        <div id="result" class="part-inputpart-row" @click="isResultShow = !isResultShow">
            <span :class="!selectResult ? 'maker':'orange'" >• </span>
            <span class="part-inputpart-row-header">结果</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="!selectResult ? '':'part-inputpart-row-normaltext' ">{{selectResult}}</span>
            <img class="part-inputpart-row-right" :class=" isResultShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../assets/images/triangle.png">
        </div>

        <div id="resonable" class="part-inputpart-row" v-if="!okState" @click="reason" >
            <span :class="!selectResonable? 'maker':'orange'" >• </span>
            <span class="part-inputpart-row-header">原因</span>
            <span class="content-divide">|</span>
            <span class="resonable-graytext" :class="!selectResonable? '':'part-inputpart-row-normaltext' ">{{selectResonable}}</span>
            <img class="part-inputpart-row-right" :class=" isResonableShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../assets/images/triangle.png">
        </div>

        <div class="roomDescription">
            <div class="roomDescriptionRemake">房屋描述</div>
            <textarea v-model="remarks" placeholder="您遇到了什么问题？" class="remake"/>
        </div>
        <van-button :class="[!selectResonable || !selectResult ? 'saveButton_Disable':'saveButton_Enable' ]"
                    :disabled=" !selectResonable || !selectResult" @click="seeWithComfirm">
            确认
        </van-button>
      <van-popup v-model="isResultShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="typeList"
            value-key="dictionaryTitle"
            @cancel="isResultShow = false"
            @confirm="selectResultOption"
        />
      </van-popup>
      <van-popup v-model="isResonableShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="notOkState?notOptimisticReasonList:noSeeState? notSeeReasonList:okState?resultArr:arryEmpty"
            :value-key="notOkState?'notOptimisticReasonName':noSeeState?'notSeeReasonName':okState? 'name':''"
            @cancel="isResonableShow = false"
            @confirm="selectResonableOption"
        />
      </van-popup>

        <loading v-if="loadingFlag"></loading>
    </div>
</template>

<script>
import {Button, NavBar, Picker, Popup,Toast} from "vant";
import getDoorPassword from '../../components/rongxun/rx-doorPassword/GetDoorPassword'
import {seeWithInit,seeWithGetPassword,seeWithComfirm,queryBaseData} from "../../getData/getData";
import {checkAndroid, checkIOS,globaluserId, getStaffId,  responseUtil} from '../../libs/rongxunUtil'
import rxNavBar from '../../components/rongxun/rx-navBar/navBar'

import BMap from 'BMap'

//*************通过app获取当前位置 start*****************
function getLocationInfo() {
    let info = window.himi.getGPSInfo();
    let locationInfo = JSON.parse(info);
    localStorage.setItem('staffLat', locationInfo.latitude)
    localStorage.setItem('staffLng', locationInfo.longitude)
}

/********判断H5页面是否在web容器中 start*********/
function openInWebview () {/* 返回true或false; */
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
        return false;
    } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
        return false;
    } else if (ua.match(/WeiBo/i) == "weibo") {
        return false;
    } else {
        if (ua.match(/Android/i) != null) {
            return ua.match(/browser/i) == null;
        } else if (ua.match(/iPhone/i) != null) {
            return ua.match(/safari/i) == null;
        } else {
            return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
        }
    }
}
/********判断H5页面是否在web容器中 start*********/
    export default {
        components: {
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            getDoorPassword,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Toast.name]: Toast,
            rxNavBar
        },

        name: "takeALookResult",
        data(){
            return{
                houseLng: '0', //楼盘经度
                houseLat: '0', //楼盘纬度
                staffLng: '0', //员工经度
                staffLat: '0', //员工纬度

                scopeRadius:30,   //范围半径
                remarks: '',
                isHaveDevice: '1',
                //获取密码 start
                isShow:true,
                temporaryDoorPassWord:'',
                temporaryPublicDoorPwd:'',
                isMobileShow:false,
                //获取密码 end
                selectResult: '',
                selectResonable: '',
                isResultShow: false,
                isResonableShow:false,
                isResultSelect: '',
                isResonableSelect:'',
                arryEmpty:[],
                resultArr: [{
                    name: '看好',
                }
                ],
                loadingFlag: false,

                resonableArr: [
                    '原因1',
                    '原因2',
                    '原因3',
                    '原因4'
                ],
                seeWith_id:'',//带看id
                longitude:'',//经度
                latitude:'',//维度
                room_id :'',//间id
                sethouseId: '', //套id
                seeWithResult:'',//带看状态
                notOptimisticReasonList:[],//未看好数据
                notOptimisticReason_id:'',//未看好原因id
                notSeeReasonList:[],//未看数据
                notSeeReason_id:'',//未看原因id
                isInRange:'',//是否在范围内
                publicPwd:'',//密码
                privatePwd:'',//密码
                typeList:[],//带看结果列表
                notOkState:false,//未看好状态
                okState:false,//看好
                noSeeState:false//未看
            }
        },
        created:function () {
            let that = this;
            debugger
            if(openInWebview()){
                if(checkAndroid()){
                    that.getLocationInfo();
                }else if(checkIOS()){
                    window.webkit.messageHandlers.getGPSInfo.postMessage({});
                }
            }else{
                localStorage.setItem('staffLat', '38.862059')
                localStorage.setItem('staffLng', '121.516107')
            }

            //*****************H5 与ios交互，将此方法暴露给window ***************
            window.getLngAndLat = that.getLngAndLat
            //*****************H5 与ios交互，将此方法暴露给window ***************
            that.houseLng = parseFloat(that.$route.query.longitude);
            that.houseLat = parseFloat(that.$route.query.latitude);
            // debugger
            // 当前员工的经纬度位置   从app获取
            that.staffLng = parseFloat(JSON.parse(localStorage.getItem('staffLng'))); //先读取local里存储的历史记录
            that.staffLat = parseFloat(JSON.parse(localStorage.getItem('staffLat')));
            that.room_id = that.$route.query.room_id;
            that.sethouseId = that.$route.query.sethouseId;
            that.seeWith_id = that.$route.query.seeWith_id;
            that.isHaveDevice = that.$route.query.isHaveDevice; //是否有智能设备  是 0  否 1

            // that.seeWithInit();//初始化接口
        },
        watch: {
            'staffLng'(newVal){
                if(isNaN(Number(newVal))){
                    let that = this;
                    // window.location.reload()
                    responseUtil.alertMsg(that,'用户名或密码错误')
                }
                if(!isNaN(Number(newVal))){
                    this.seeWithInit();
                    this.seeWithGetPassword()//获取密码接口
                    this.initDicData();//字典表查询
                }
            }
        },
        methods:{

            //暴露给winow的getLngAndLat方法
            getLngAndLat(lng,lat){
                localStorage.setItem('staffLat', lat)
                localStorage.setItem('staffLng', lng)
            },

            addMarker(staffPoint,map){
                var marker = new BMap.Marker(staffPoint);
                map.addOverlay(marker);
            },

            //创建圆形区域
            createCircle: function () {
                let that = this;
                var map = new BMap.Map("container");
                //右上角缩小、放大按钮
                var top_right_navigation = new BMap.NavigationControl({anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL});
                //创建中心点
                var pt = new BMap.Point(that.houseLng, that.houseLat)
                map.centerAndZoom(pt, 19)
                var marker = new BMap.Marker(pt)
                var circle = new BMap.Circle(pt, that.scopeRadius, {
                    strokeColor: 'Red',
                    strokeWeight: 1,    //边的宽度
                    strokeOpacity: 1,
                    Color: 'Red',
                    fillColor: '#f03',
                    fillOpacity: 0.2
                })// 创建圆形区域
                map.addOverlay(marker)
                map.addOverlay(circle)
                map.addControl(top_right_navigation);
                //员工经纬度
                var staffPoint = new BMap.Point(that.staffLng,that.staffLat);
                // let staffPoint = new BMap.Point(121.661353, 38.9257);
                this.addMarker(staffPoint,map);
            },

            //通过app获取当前位置
            getLocationInfo(){
                getLocationInfo();
            },
            reason(){
                // debugger
                if (!this.noSeeState && !this.notOkState&&!this.okState){
                   this.isResonableShow=false
                }else  {
                    // this.isResonableShow=false
                    this.isResonableShow = !this.isResonableShow
                }
            },
            //地图
            handler ({BMap, map}) {
                let me = this;
                // 鼠标缩放
                map.enableScrollWheelZoom(true);
                // 点击事件获取经纬度
                map.addEventListener('click', function (e) {
                })
            },

            // 带看结果选项
            selectResultOption(item) {
                if (item.dictionaryValue==1){//未看好状态
                    this.seeWithResult=item.dictionaryValue;
                    this.notOkState=true;
                    this.okState=false
                    this.noSeeState=false
                    this.isResonableSelect=false;
                    this.selectResonable=''
                }else if(item.dictionaryValue==2){//看好状态
                    this.seeWithResult=item.dictionaryValue
                    this.okState=true
                    this.noSeeState=false;
                    this.notOkState=false;
                    this.isResonableSelect=true;
                    this.selectResonable='看好'
                }else {//未看状态
                    this.seeWithResult=item.dictionaryValue
                    this.noSeeState=true
                    this.notOkState=false;
                    this.okState=false
                    this.isResonableSelect=false;
                    this.selectResonable=''
                }
                console.log(this.seeWithResult )
                this.selectResult = item.dictionaryTitle
                this.isResultShow = false
                this.isResultSelect = true
            },
            //  初始化字典数据
            initDicData() {
                const that = this
                let initData = {}
                initData.fdName = ['APPOINTMENTSTATUSMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.typeList = response.data.data.APPOINTMENTSTATUSMAP;
                        that.typeList.pop()
                        that.typeList.shift();

                    })
                })

            },
            //页面初始化接口
            seeWithInit: function () {
                let that = this
                let initData = {
                    seeWith_id:that.seeWith_id,
                    longitude:that.staffLng,
                    latitude:that.staffLat,
                    id: that.room_id,
                }
                seeWithInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code==0){
                            that.notOptimisticReasonList=response.data.data.notOptimisticReasonList;//未看好原因
                            that.isInRange=response.data.data.isInRange//是否在范围内   1 是  0 否
                            that.scopeRadius=response.data.data.scopeRadius//最小距离 用于前台地图范围的半径
                            that.notSeeReasonList=response.data.data.notSeeReasonList//未看原因
                        }
                        that.createCircle()
                    })
                })
            },
            //获取密码接口
            seeWithGetPassword: function () {
                var that = this
                let initData = {
                    seeWith_id:that.seeWith_id,
                    longitude:that.staffLng,
                    latitude:that.staffLat,
                    id: that.room_id,
                    setHouse_id: that.sethouseId,
                    roomHouse_id: that.room_id,
                    user_id:globaluserId(),
                    type: '1',  //看房
            }
                seeWithGetPassword(initData).then(function (response) {
                console.log(response)
                responseUtil.dealResponse(that, response, () => {
                    if (response.data.code==0){
                        that.publicPwd=response.data.data.publicPwd;
                        that.privatePwd=response.data.data.privatePwd;
                        that.temporaryDoorPassWord = that.privatePwd
                        that.temporaryPublicDoorPwd = that.publicPwd
                        that.isMobileShow = !that.isMobileShow;
                    }
                })
            })
        },
            //确认带看录入接口
            seeWithComfirm: function () {
                var that = this
                that.loadingFlag = true;
                let initData = {
                    seeWith_id:that.seeWith_id,
                    longitude:that.staffLng,
                    latitude:that.staffLat,
                    user_id:getStaffId(),
                    seeWithResult:that.seeWithResult,
                    notOptimisticReason_id:that.notOptimisticReason_id,
                    notSeeReason_id:that.notSeeReason_id,
                    id: that.room_id,
                    remark: that.remarks,
                }
                seeWithComfirm(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code==0){
                            responseUtil.alertMsg(that,response.data.msg)
                            setTimeout(()=>{
                                that.leftReturn();
                            },1)

                        }
                    })
                })
            },

            //获取房门密码
            getDoorPassword() {
                let that = this;
                // that.temporaryDoorPassWord = e.row.ownerMobile;
                // that.temporaryOwnerName = e.row.ownerName
                that.seeWithGetPassword()

            },
            //关闭获取密码弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },

            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            // 检查是否已选择看带结果
            clickResultShow() {
                this.isResultShow = !this.isResultShow;
                var panel = document.getElementById('result')
                document.addEventListener('click', e => {
                    if (!panel.contains(e.target)) {
                        this.isResultShow = false
                    }
                })
            },

            //检查是否已选择原因
            clickResonableShow() {
                this.isResonableShow = !this.isResonableShow;
                var panel = document.getElementById('resonable')
                document.addEventListener('click', e => {
                    if (!panel.contains(e.target)) {
                        this.isResonableShow = false
                    }
                })
            },
            // 原因选项
            selectResonableOption(item) {
             if (this.notOkState) {
                 console.log(item)
                 this.selectResonable = item.notOptimisticReasonName
                 this.notOptimisticReason_id= item.notOptimisticReason_id//未看原因id

             }else if(this.noSeeState){
                 console.log(item)
                 this.selectResonable = item.notSeeReasonName
                 this.notSeeReason_id= item.notSeeReason_id//未看好原因id
             }
                this.isResonableShow = false
                this.isResonableSelect = true
            },
        }
    }
</script>

<style scoped>

.part-inputpart-row-normaltext{
    color: #ff5809;
}
    .nav_bar_title{
        z-index: 99;
    }
    /*获取密码*/
    .getCode{
        display:flex;
        width: 345px;
        height: 50px;
        background-color: white;
        border-radius: 10px;
        margin: 55px 0 5px 15px;
    }
    .getCodeIcon{

        width: 36px;
        height: 30px;
        margin: 10px 5px 8px 5px;
    }
    .sp{
        font-size: 13px;
        color: gray;
        margin: 18px 0 17px 0;
    }
    /*带看结果*/
    .takeALookResult{
        float: left;
        width: 92%;
        height: 17px;
        color: #FF5D3B;
        font-size: 12px;
        font-weight: bold;
        margin: 20px 0 5px 15px;
    }
    /*选择带看的结果*/
    .part-inputpart-row {
        float: left;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 0 5px 1px 15px;
    }
    /*房屋描述*/
    .roomDescription{
        float: left;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        height: 135px;
        border-radius: 8px;
        margin: 0 5px 1px 15px;
    }
    .roomDescriptionRemake{
        float: left;
        margin: 10px 0 5px 20px;
        font-size: 15px;
        font-weight: bold;
    }
    textarea {
        resize: none;
    }
    /*备注输入框*/
    .remake{
        color: gray;
        width: 310px;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 5px 10px 0 10px;
        background-color: #f8f8f8;
        font-size: 13px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
    }
    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }
    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option{
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }
    .part-inputpart-dropDownList{
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 90px;
    }
    .resonable-dropDownList{
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 155px;
    }
    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0 10px;
    }
    /*圆点样式---灰色*/
    .maker{
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 15px;
    }
    /*圆点样式---橘色*/
    .orange{
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 15px;
    }
/*结果框*/
    .part-inputpart-row-header{
        font-weight: bold;
        font-size: 14px;
        width: 50px;
    }
    .part-inputpart-row-graytext {

        width: 100%;
        font-size: 14px;

    }
    .resonable-graytext {
        width: 100%;
        font-size: 14px;

    }
    .optionSelectedColor{
        color: #ff5d3b;
    }
    .part-inputpart-row-right{
        float: right;
        margin-right: 15px;
    }
    /*确认按钮不可点击样式*/
    .saveButton_Disable{
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:70px 0 0 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    /*确认按钮可点击样式*/
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:70px 0 0 15px ;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;

    }
    /*地图样式*/
    #container{
        height:240px;
        width: 92%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 20px;
    }
</style>

